<template>
    <div class="flex flex-column flex-auto">
        <div class="p-5">
            <div class="grid">
                <div class="col-12">
                    <div class="surface-card shadow-2 border-round p-3 mb-3">
                        <div class="flex flex-row justify-content-between">
                            <p class="flex font-bold capitalize text-left text-2xl">404 Page not found</p>
                        </div>
                    </div>
                </div>

                <div class="col-offset-3 col-6">
                    <div class="surface-card shadow-2 border-round p-3 mb-3">
                        <div class="flex justify-content-between">
                            <div class="flex align-items-center justify-content-center">
                                <div class="flex px-3">
                                    <p class="text-xl font-bold text-center">
                                        How'd you get here?!<br>
                                        Click <a href="./">here</a> to return home</p>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-center right-1">
                                <img class="border-round mb-4" :src="require('../assets/images/lost.gif')">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style scoped>

</style>
